import PropTypes from 'prop-types';

export const flipperFeaturesShape = PropTypes.shape({
  realtimeWowza: PropTypes.bool,
  liveSaveStreamEnabled: PropTypes.bool,
  liveStaticEmbedKeyEnabled: PropTypes.bool,
  liveCaptionConductorSpanishEnabled: PropTypes.bool,
});

export const liveTranscoderShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  default: PropTypes.bool,
  externalId: PropTypes.string,
  url: PropTypes.string,
  validForLac: PropTypes.bool,
  validForLpc: PropTypes.bool,
});

export const scheduledEventsShape = PropTypes.shape({
  streamStartTime: PropTypes.string,
  embedCodeIframe: PropTypes.string,
  embedCodeJs: PropTypes.string,
  name: PropTypes.string,
  eventStartTime: PropTypes.string,
  streamDetails: PropTypes.shape({
    rtmpKey: PropTypes.string,
    streamUrl: PropTypes.string,
  }),
});
