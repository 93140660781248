import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { getCsrfToken } from '~/helpers/authtokenHelper';

export function ReportNoAudioButton({ reportNoAudioPath, scheduledStartTimeISO, submitCaption }) {
  const initialDisabledState = scheduledStartTimeISO
    ? new Date() < new Date(scheduledStartTimeISO)
    : false;
  const [disabled, setDisabled] = useState(initialDisabledState);

  useEffect(() => {
    if (!scheduledStartTimeISO) return;

    const scheduledStartTime = new Date(scheduledStartTimeISO);
    const now = new Date();
    const timeUntilStart = scheduledStartTime - now;

    const MAX_TIMEOUT = 2147483647; // Maximum safe timeout (~24.8 days) in browser

    if (timeUntilStart < 0) {
      setDisabled(false);
    } else if (timeUntilStart <= MAX_TIMEOUT) {
      const timerId = setTimeout(() => setDisabled(false), timeUntilStart);
      return () => clearTimeout(timerId);
    }
  }, [scheduledStartTimeISO]);

  const handleClick = async () => {
    setDisabled(true);
    if (reportNoAudioPath) {
      await fetch(reportNoAudioPath, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken(),
        },
      });
    }
    submitCaption('[NO AUDIO]');
    setDisabled(false);
  };

  return (
    <Button disabled={disabled} onClick={handleClick} type="button" variant="warning">
      No Audio
    </Button>
  );
}

ReportNoAudioButton.propTypes = {
  reportNoAudioPath: PropTypes.string,
  // This is intentionally a string to guarantee it is stable and doesn't have object reference issues
  scheduledStartTimeISO: PropTypes.string,
  submitCaption: PropTypes.func.isRequired,
};
